import View from "ol/View";
import {fromLonLat} from "ol/proj";
import Map from "ol/Map";
import TileLayer from "ol/layer/Tile";
import OSM from "ol/source/OSM";
import {Overlay} from "ol";
import VectorSource from "ol/source/Vector";
import {GeoJSON} from "ol/format";
import VectorLayer from "ol/layer/Vector";
import {Fill, Stroke, Style} from "ol/style";
import moment from 'moment-timezone';
import { openDB } from 'idb';

const upgradeDB = (db) => {db.createObjectStore('geoStorage', { keyPath: 'id' });};

(async () => {
    if (window.map_run){
        return;
    }
    window.map_run = true;

    const view = new View({
        center: fromLonLat([32.57673844125193, 48.80296295563301]),
        zoom: window.innerWidth > window.innerHeight ? 6 : 5
    });

    const map = new Map({
      layers: [
        new TileLayer({
          source: new OSM(),
        }),
      ],
      target: 'map',
      view: view,
    });

    window.mmm = map;

    const popup = new Overlay({
        element: document.createElement('div'),
    });
    popup.getElement().classList.add('popup');
    map.addOverlay(popup);


    map.on('singleclick', function (evt) {

        let layers = [];
        map.forEachFeatureAtPixel(evt.pixel, (feature, layer) => {layers.push(layer);});
        if (layers.length === 0){
            popup.setPosition(null);
            return;
        }

        popup.getElement().innerHTML = `<div class="mdc-card mdc-card--outlined popup-card">
    <div class="mdc-card-wrapper__text-section">
    <div class="region-card__title popup-card-name"></div>
    <div class="region-card__subhead popup-card-content"></div>
    </div>
    </div>`;

        popup.setPosition(evt.coordinate);
        for (let layer of layers){
            document.querySelector('.popup-card-name').innerHTML += `${layer.get('name').replaceAll('_', ' ')} `;
            document.querySelector('.popup-card-content').innerHTML += `<p>${layer.get('message')}</p><a href="/regions/${layer.get('name')}" class="mdc-button mdc-button-header mdc-button--outlined mdc-button--primary">
                <span class="mdc-button__ripple"></span>
                <span class="mdc-button__label">Історія</span>
              </a>`;
        }

    });

    const clearAllFeaturesFromMap = () => {
        let a = map.getAllLayers();
        for (let i = 1; i < a.length; i++){
            let features = a[i].getSource().getFeatures();
            features.forEach((feature) => {
                feature.setGeometry(null);
                a[i].getSource().removeFeature(feature);
            });
            a[i].getSource().clear();

            map.removeLayer(a[i]);
        }
    };

    let timePicker = document.getElementById('time-pick');


    const drawRegion = (geoJson, alert) => {
        let source = new VectorSource({
          features: (new GeoJSON()).readFeatures(geoJson, {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
          })
        });

        let layer = new VectorLayer({
            source: source,
            style: () => {
                if (alert.status === 1){
                    return new Style({
                        stroke: new Stroke({
                          color: 'red',
                          width: 2,
                        }),
                        fill: new Fill({
                          color: 'rgba(255,0,0,0.2)',
                        }),
                      });
                }
                else {
                    return  new Style({
                        stroke: new Stroke({
                          color: '#FFAA00',
                          width: 2,
                        }),
                        fill: new Fill({
                          color: 'rgba(255,170,0,0.2)',
                        }),
                      });
                }
            },
            properties: {
                name: alert.location.name,
                message: alert.message
            }
        });

        map.addLayer(layer);
    };

    const updateGeo = async (alert) => {

        let resp = await fetch(`/api/geo/${alert.location.pk}/`);
        let respData = await resp.json();

        const db = await openDB('geodb', 1, {upgrade: upgradeDB});

        await db.transaction('geoStorage', 'readwrite').objectStore('geoStorage').put( {
            id: alert.location.pk,
            geo: respData.geojson
        });
        db.close();
        return respData.geojson;
    };

    const processAlerts = async (alert_data) => {
        let tmpGeo = [];
        for (let alert of alert_data){

            try {
                const db = await openDB('geodb', 1, {upgrade: upgradeDB});
                let geoJson = await db.transaction('geoStorage').objectStore('geoStorage').get(alert.location.pk);
                db.close();
                if (!geoJson){
                    let res = await updateGeo(alert);
                    tmpGeo.push(res);
                }
                if (geoJson && geoJson.geo.geometries){
                    tmpGeo.push(geoJson.geo);
                }
            } catch (e){
                console.log(e);
                let res = await updateGeo(alert);
                tmpGeo.push(res);
            }

        }
        return tmpGeo;
    };

    const updateMap = async () => {
        let url = '/api/alerts/';
        if (date_to){
            url += `?date_to=${date_to}`;
        }

        let resp = await fetch(url);
        let alert_data = await resp.json();

        const tmpGeo = await processAlerts(alert_data);
        clearAllFeaturesFromMap();
        alert_data.forEach((alert, i) => {
            drawRegion(tmpGeo[i], alert);
        });

        localStorage.setItem('geoCache', JSON.stringify(alert_data));
        if (updateTimer && timePicker.value !== moment(new Date()).tz('Europe/Kiev').format('YYYY-MM-DDTHH:mm')){
            timePicker.value = moment(new Date()).tz('Europe/Kiev').format('YYYY-MM-DDTHH:mm');
        }
    };


    let date_to = null;
    let updateTimer = null;

    if (timePicker){
      timePicker.value = moment(new Date()).tz('Europe/Kiev').format('YYYY-MM-DDTHH:mm');
      timePicker.addEventListener('change', () => {
          date_to = timePicker.value;
          if (updateTimer){
             clearInterval(updateTimer);
             updateTimer = null;
         }
         updateMap();
          if (timePicker.value === moment(new Date()).tz('Europe/Kiev').format('YYYY-MM-DDTHH:mm') && updateTimer === null){
              date_to = null;
              updateTimer = setInterval(updateMap, 15000);
          }

      });
    }

    let geoCache = localStorage.getItem('geoCache');
    if (geoCache){
        let alert_data = JSON.parse(geoCache);
        const tmpGeo = await processAlerts(alert_data);
        alert_data.forEach((alert, i) => {
            drawRegion(tmpGeo[i], alert);
        });
    }

    await updateMap();
    updateTimer = setInterval(updateMap, 15000);

})();